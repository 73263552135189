import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function LayoutSubPage(props) {
  return (
    <main id="main">
      <Header path={props.path} />
      {props.children}
      <Footer />
    </main>
  );
}
