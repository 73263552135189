const API = {
  ACTIVITY: "/api/get/recently-votes",
  CONTESTANT: "/api/get/contestants",
  RANK: "/api/get/rank",
  FAVORITE: "/api/get/favorite-rank",
  CONTESTANT_DETAIL: (missId) => `/api/get/contestant/${missId}`,
  VOTE_LIST: (missId, pageNumber, pageSize) =>
    `/api/get/contestant-votes/${missId}/${pageNumber}/${pageSize}`,
  PAY_URL: (gateway, missId, combo) => `/api/${gateway}/${missId}/${combo}`,
};

export default API;
