/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

export default function SectionBanner() {
  return (
    <section className="section-banner">
      <div className="banner-content">
        <img alt="banner" src="/images/banner.png" />
      </div>
    </section>
  );
}
