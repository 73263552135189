export default {
  vi: {
    block: 'Khối',
    currentHash: 'Hash khối hiện tại',
    previousHash: 'Hash khối trước',
    nextHash: 'Hash khối sau',
    voter: 'Người bình chọn',
    voteId: 'Mã bình chọn',
    voterId: 'Mã người bình chọn',
    voted: 'Đã bình chọn cho',
    contestant: 'Thí sinh',
    time: 'Thời gian',
    director: 'Ban tổ chức',
    sponsor: 'Đơn vị đồng hành',
    id: 'SBD',
    points: 'Điểm',
    votePoints: 'Điểm bình chọn',
    detail: 'Chi tiết',
    none: 'Không có',
    numberOfVotes: 'Số lượng phiểu bầu',
    copyright: 'Bản quyền 2019 bởi',
    adOffer: 'Mời quảng cáo',
    name: 'Họ tên',

    'nav.home': 'Trang chủ',

    'nav.intro': 'Giới thiệu',
    'nav.competition': 'Cuộc thi',
    'nav.organization': 'Đơn vị tổ chức',
    'nav.judges': 'Ban giám khảo',
    'nav.price': 'Giải thưởng',

    'nav.schedule': 'Lịch trình',
    'nav.batch': 'Đợt thi',
    'nav.preliminary': 'Vòng sơ khảo',
    'nav.online': 'Vòng trực tuyết thực tế',
    'nav.final': 'Đêm chung kết',

    'nav.rules': 'Thể lệ',
    'nav.voting': 'Thể lệ bình chọn',
    'nav.guessing': 'Thể lệ dự đoán',

    'nav.rank': 'Bảng xếp hạng', //Bảng xếp hạng

    'nav.result': 'Kết quả',

    'index.continue': 'Xem thêm',
    'index.recently': 'Hoạt động gần đây',
    'index.contestants': 'Danh sách thí sinh',
    'index.partner': 'Đối tác khách hàng',
    'contestant.birthday': 'Sinh nhật',
    'contestant.gender': 'Giới tính',
    'contestant.class': 'Lớp',
    'contestant.school': 'Trường',
    'contestant.district': 'Quận - Huyện',
    'contestant.provincial': 'Tỉnh',
    'contestant.city': 'Thành phố',
    'contestant.work': 'Nghề nghiệp',
    'contestant.height': 'Chiều cao',
    'contestant.weight': 'Cân nặng',
    'contestant.bwh': 'Số đo 3 vòng',
    'contestant.id': 'Số báo danh',
    'contestant.home': 'Quê quán',
    'contestant.sms': 'Bình chọn qua Sms',
    'contestant.momo': 'Bình chọn qua MoMo',
    'contestant.vinId': 'Bình chọn qua VINID',
    'contestant.social': 'Bình chọn qua Facebook',
    'contestant.smsNumbers': 'Bình chọn Sms',
    'contestant.momoNumbers': 'Bình chọn MoMo',
    'contestant.socialNumbers': 'Bình chọn Facebook',
    'contestant.guess': 'Dự đoán',
    'contestant.share': 'Chia sẻ',
    'contestant.history': 'Lịch sử bình chọn',
    'contestant.index': 'STT',
    'contestant.continue': 'Tiếp tục',
    'contestant.likePage': 'Nhấn nút "Thích Trang" để tiếp tục hành trình',
    'contestant.enterPhone': 'Nhập số điện thoại của bạn',
    'contestant.confirm': 'Xác nhận',
    'contestant.payMomo': 'Thanh toán với MoMo',
    'contestant.wait':
      'Vui lòng chờ hệ thống Blockchain xử lý để tiến hành bước tiếp theo',
    'contestant.skip': 'Bỏ qua',
    'contestant.watchAll': 'Xem tiếp',
    'contestant.skipAfter': 'Bỏ qua sau',
    'contestant.remain': 'Còn lại',
    'contestant.processing': 'Đang xử lý',
    'contestant.login': 'HÃY ĐĂNG NHẬP BẰNG TÀI KHOẢN FACEBOOK',
    'contestant.smsTitle': 'Bạn hãy nhắn tin theo cú pháp',
    'contestant.contestId': 'Mã bình chọn chương trình',
    'contestant.note': 'Lưu ý :',
    'contestant.noteMes': '- Phí mỗi tin nhắn là 10.000 VND',
    'contestant.smsExample': 'Ví dụ cú pháp đúng',
    'contestant.send': 'Gửi',
    'contestant.desc': 'Vài nét về bản thân',

    'result.totalPrize': 'Tổng giá trị giải thưởng',
    'result.prizeNote': 'Bao gồm tiền vào hiện vật',
    'result.mister': 'Mister 2019',
    'result.live': 'Đêm chung kết sẽ được truyền hình trực tiếp',
    'result.subContest': 'Giải phụ',
    'result.mister1': 'Quán quân',
    'result.mister1Prize': '1 tỷ đồng',
    'result.mister2': 'Á quân 1',
    'result.mister2Prize': '500 triệu đồng',
    'result.mister3': 'Á quân 2',
    'result.mister3Prize': '500 triệu đồng',
  },
  en: {
    block: 'Block',
    currentHash: 'Current hash',
    previousHash: 'Previous hash',
    nextHash: 'Next hash',

    voter: 'Voter',
    voteId: 'Vote ID',
    voterId: 'Voter ID',
    voted: 'Voted for',
    contestant: 'Contestant',
    time: 'Time',
    director: 'Broad of Directors',
    sponsor: 'Sponsor',
    id: 'ID',
    points: 'Points',
    votePoints: 'Vote points',
    detail: 'Detail',
    none: 'None',
    numberOfVotes: 'Number of votes',
    copyright: '2019 Bytesoft JSC. All right reserved',
    adOffer: 'Advertising offers',
    name: 'Name',

    'nav.home': 'Home',

    'nav.intro': 'Introduce',
    'nav.organization': 'Organization unit',
    'nav.judges': 'Judges',
    'nav.price': 'Prize',

    'nav.schedule': 'Schedule',
    'nav.batch': 'Batch',
    'nav.preliminary': 'Preliminary round',
    'nav.online': 'Online round',
    'nav.final': 'Final round',

    'nav.rules': 'Rules',
    'nav.voting': 'Voting rules',
    'nav.guessing': 'Guessing rules',

    'nav.rank': 'Rank',

    'nav.result': 'Result',

    'index.continue': 'More',
    'index.recently': 'Recent votes',
    'index.contestants': 'Contestant list',

    'contestant.birthday': 'Year of birth',
    'contestant.gender': 'Gender',
    'contestant.class': 'Class',
    'contestant.school': 'School',
    'contestant.district': 'District',
    'contestant.provincial': 'Provincial',
    'contestant.city': 'City',
    'contestant.work': 'Job',
    'contestant.height': 'Height',
    'contestant.weight': 'Weight',
    'contestant.bwh': 'Measurements',
    'contestant.id': 'ID',
    'contestant.home': 'Hometown',
    'contestant.sms': 'Vote by Sms',
    'contestant.momo': 'Vote by MoMo',
    'contestant.vinId': 'Vote by VINID',
    'contestant.social': 'Vote by Facebook',
    'contestant.smsNumbers': 'Sms votes',
    'contestant.momoNumbers': 'MoMo votes',
    'contestant.socialNumbers': 'Facebook votes',
    'contestant.guess': 'Guess',
    'contestant.share': 'Share',
    'contestant.history': 'Vote history',
    'contestant.index': '#',
    'contestant.continue': 'Continue',
    'contestant.likePage': 'Click "Like" to continue the process',
    'contestant.enterPhone': 'Enter your phone number',
    'contestant.confirm': 'Confirm',
    'contestant.payMomo': 'Pay with MoMo',
    'contestant.wait':
      'Please wait for the Blockchain system to process to the next step',
    'contestant.skip': 'Skip',
    'contestant.watchAll': 'Watch all',
    'contestant.skipAfter': 'Skip after',
    'contestant.remain': 'Remain',
    'contestant.processing': 'Processing',
    'contestant.login': 'Please login to Facebook account to continue',
    'contestant.note': 'Note :',
    'contestant.smsTitle': 'Please text the message below',
    'contestant.contestId': 'Contest ID',
    'contestant.noteMes': '- Each mesage fee is 10,000 VND',
    'contestant.smsExample': 'Example correct message',
    'contestant.send': 'Send',

    'result.totalPrize': 'Total prize',
    'result.prizeNote': 'Including cash and in kind',
    'result.live': 'Final round will be lived on TV ',
    'result.mister': 'Mister 2019',
    'result.subContest': 'Sub-tilte',
    'result.mister1': 'Miss Universe Vietnam 2019',
    'result.mister1Prize': '1 billion VND',
    'result.mister2': 'The 1st runner-up',
    'result.mister2Prize': '500 million VND',
    'result.mister3': 'The 2nd runner-up',
    'result.mister3Prize': '500 million VND',
  },
};
