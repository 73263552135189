/* eslint-disable jsx-a11y/anchor-has-content */
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import styled from "styled-components";
const SectionBrand = () => {
  const [ShowMore, setShowMore] = useState(true);
  return (
    <Styles
      className="section-branch aos-init aos-animate"
      data-aos="fade-down"
      data-aos-delay={0}
    >
      <div className="branch-content">
        <div className="bs-container">
          <div className="bs-row">
            <div className="bs-col">
              <div className="module module-branch">
                <div className="module-header" data-aos="fade-up">
                  {/* <span className="id">04</span> */}
                  <h2 className="title">
                    <FormattedMessage id="index.partner" />
                  </h2>
                </div>
                <div className={`module-content ${ShowMore ? "active" : ""}`}>
                  <div className="branch-width">
                    <div className="branch-slide">
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bvote.com.vn/index.html"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/logo-header.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytesoft.vn/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/branch_item1.gif" alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/bytenext_logo.png" alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/reavol-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/jobbooking-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/sailfish-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/c3group-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://bytenext.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/behome-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/avatarart-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/langro-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="branch-item">
                          <a
                            href="https://avatarart.io/"
                            className="link"
                            target="__blank"
                          >
                            <img src="/images/bytepay-logo.png" alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span
                  className="show__more"
                  onClick={() => setShowMore(!ShowMore)}
                >
                  »
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default SectionBrand;

const Styles = styled.section`
  .branch-content {
    position: relative;
    &::before {
      content: "";
      background-image: url("../../images/befo_branch.png");
      width: 103px;
      height: 113px;
      position: absolute;
      top: 97%;
      right: 155px;
      background-repeat: no-repeat;
      z-index: -1;
    }
    .module-branch {
      position: relative;
      &::before {
        content: "";
        background-image: url("../../images/befo_branch.png");
        width: 103px;
        height: 113px;
        position: absolute;
        top: 45px;
        left: -150px;
        background-repeat: no-repeat;
        z-index: -1;
      }
      .module-header {
        .title {
          padding-left: 0px;
          margin: 0px;
          padding-top: 50px;
        }
      }
      .module-content {
        position: relative;
        &::before {
          content: "";
          background-image: url("../../images/befo_branch.png");
          width: 103px;
          height: 113px;
          position: absolute;
          top: 45px;
          left: -150px;
          background-repeat: no-repeat;
          z-index: -1;
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .branch-content {
      &::before {
        top: 91%;
        right: 35px;
      }
      .module-branch {
        &::before {
          top: -20px;
          left: unset;
          right: -30px;
        }
        .module-header {
          padding-top: 5px;
          .title {
            padding-top: 0px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .branch-content {
      &::before {
        top: 92%;
        right: 5px;
      }
      .module-branch {
        &::before {
          top: -20px;
          left: unset;
          right: -30px;
        }
        .module-header {
          padding-top: 5px;
          .title {
            padding-top: 0px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 480px) {
    margin-top: -20px;
    .branch-content {
      &::before {
        display: none;
      }
      .module-branch {
        &::before {
          top: -40px;
          left: unset;
          right: -30px;
        }
        .module-header {
          padding-top: 5px;
          .title {
            padding-top: 0px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    margin-top: -10px;
    .branch-content {
      .module-branch {
        .module-header {
          padding-top: 5px;
          .title {
            padding-top: 0px;
          }
        }
      }
    }
  }
`;
