import io from "socket.io-client";

const socket = io.connect(`${process.env.REACT_APP_SERVER}`, {
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionAttempts: Infinity,
  transports: ["websocket"],
});

function connectToSocket(cb) {
  socket.on("message", (data) => cb(null, data));
}

function disconnectToSocket() {
  socket.disconnect();
}

export { connectToSocket, disconnectToSocket };
