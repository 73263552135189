import axios from "axios";

const instanceAxios = {
  baseURL: process.env.REACT_APP_SERVER,
};

const axiosConfig = axios.create(instanceAxios);

export const request = ({ method, url, data, ...rest }) => {
  const options = { method, url, data, ...rest };
  return axiosConfig(options);
};

export const configError = (err) => {
  const temp = err?.response?.data?.message || "Error";
  if (typeof temp == "string") return temp;
  return temp[0];
};
