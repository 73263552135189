import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

// import { connectToSocket, disconnectToSocket } from '../../utils/listenSocket';

// NOTE: Change when deploy
class Notification extends Component {
  state = {
    notifications: [],
    show: false,
  };

  componentDidMount() {
    this.props.connectToSocket((err, data) => {
      if (data && data.body) {
        this.setState({ notifications: data.body, show: true });
        setTimeout(() => {
          this.setState({ notifications: [], show: false });
        }, 5000);
      }
    });
  }

  toggleNotifications = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    const { notifications, show } = this.state;

    return (
      <React.Fragment>
        {show && notifications && notifications.length > 0 && (
          <div className="text-white notification">
            <div className="vote-notice active">
              <span
                className="close__notice"
                onClick={this.toggleNotifications}
              >
                <i className="fas fa-window-close" />
              </span>
              <p className="desc">
                <FormattedMessage id="voter" />:{' '}
                <span>{notifications[0].user_id}</span>
              </p>
              <p className="desc">
                <FormattedMessage id="voted" /> :{' '}
                <span> {notifications[0].contestant_id}</span>
              </p>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default Notification;
