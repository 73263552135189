import React from "react";
import { Link } from "react-router-dom";
import { getAvatarUrl } from "../../store";
import styled from "styled-components";

export default function ContestantCard({
  code,
  table,
  missId,
  missName,
  votePoints,
}) {
  const content = votePoints || votePoints === 0 ? votePoints : "...";

  // console.log("missId", missId);

  return (
    <Styles className="bs-col lg-25-15 md-33-15 xs-50-15">
      <div className={`item aos-init aos-animate`} data-aos="fade-left">
        {/* <div className="item-content">
          <div className="head">
            <p className="sbd">
              SBD: <span>{missId}</span>
            </p>
          </div>
          <div className="content">
            <div className="img">
              <div class="box-shadow">
                <div className="img-frame">
                  <img
                    src={`/images/AVA/${configId}.jpg`}
                    
                    alt={missId}
                    loading="lazy"
                    className="image"
                  />
                </div>
              </div>
            </div>
            <div className="text">
              <p className="name">{missName}</p>
              <p className="point">
                Điểm bình chọn: <span>{content}</span>
              </p>
              <Link to={`/thi-sinh/${missId}`} className="link">
                Bình chọn
              </Link>
            </div>
          </div>
        </div> */}
        <div className="item-content">
          {/* <div className="head"></div> */}
          <div className="content">
            <div className="img">
              <div className="box-shadow">
                <div className="img-frame">
                  <img
                    src={getAvatarUrl(code, table)}
                    alt={code}
                    loading="lazy"
                    className="image"
                  />
                </div>
              </div>
              {/* <p className="like">
                <img
                  src="images/icons/icon_like.svg"
                  alt=""
                  className="like-img"
                />
                10
              </p> */}
              <p className="sbd">
                SBD: <span>{code}</span>
              </p>
              {/* <p className="favourite">
                <BsFillSuitHeartFill className="icon" />
                <span>{favoritePoint}</span>
              </p> */}
            </div>
            <div className="text">
              <p className="name">{missName}</p>
              <p className="point">
                Điểm bình chọn: <span>{content}</span>
              </p>
              <Link to={`/thi-sinh/${missId}`} className="link">
                Bình chọn
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}
// src="/images/miss.png"

const Styles = styled.div``;
